/* eslint-disable func-names */
/* global define, MobileHelpers, updateUrlParameter */

(function (factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
  } else {
    factory(jQuery);
  }
})(function ($) {
  // cached objects
  const $win = $(window);
  const $doc = $(document);
  const $header = $('[data-js-header]');

  // first check if placeholder attribute is supported
  if ($('<input/>').attr('placeholder', '')[0].placeholder === undefined) {
    // detect placeholder on inputs
    $('input[placeholder]')
      .each(function () {
        const $self = $(this);
        if (this.value === '' || this.value === $self.attr('placeholder')) {
          $self.addClass('placeholder');
          this.value = $self.attr('placeholder');
        }
        // clear placeholder before form submit
        $self.closest('form').bind('submit', function () {
          if ($self.attr('value') === $self.attr('placeholder')) {
            $self.attr('value', '');
          }
        });
      })
      .live('focusin focusout', function (event) {
        const $self = $(this);
        const placeholder = $self.attr('placeholder');
        if (event.type === 'focusout' && this.value === '') {
          this.value = placeholder;
          $self.addClass('placeholder');
        } else if (this.value === placeholder) {
          this.value = '';
          $self.removeClass('placeholder');
        }
      });
  }

  // application data-hooks
  // hook fallback-alert-container close button
  $doc.on('click', '[data-hook="fallback-alert-container"]', function () {
    const $wrap = $(this).parent();
    $wrap.fadeOut();
    $.get(`/pages/home${$(this).attr('href')}&nolayout=1`);
    return false;
  });

  $win
    .on('load', function () {
      $win.trigger('resize');
    })

    .on('scroll', function () {
      if (MobileHelpers.usingMobileView()) {
        return;
      } /* don't use sticky header in mobile view */

      const headerCollapsed = $header.hasClass('header-wrap-collapsed');
      const scrollTop = $win.scrollTop();
      const height = $header.height();

      // switch between standard & mini header
      if (scrollTop > height && !headerCollapsed) {
        // switch to mini if scrollTop more than top bar height
        $header.addClass('header-wrap-collapsed');
      } else if (scrollTop <= height && headerCollapsed) {
        // switch to standard if scrollTop less than top bar height
        $header.removeClass('header-wrap-collapsed');
      }

      /*
          Add border to secondary nav on detach from top
          20px is the baseline
        */
      if (scrollTop > 0) {
        $header.addClass('header-wrap-detached');
      } else {
        $header.removeClass('header-wrap-detached');
      }
    });

  // more info
  $('label[data-more-info]').each(function () {
    $(this).append(
      `<span rel="tooltip" class="c-badge c-badge--help" data-toggle="tooltip" data-container="body" title="${$(
        this,
      ).data('more-info')}"></span>`,
    );
  });

  /*
    initialize tooltips
    ----
    use data-toggle="tooltip" to initialize tooltips

    use data-attributes to pass specific configuration options
    optiosn: https://getbootstrap.com/docs/3.3/javascript/#tooltips

    Unless specific situation (styling override) is always recommended to use
    data-container="body" attribute to avoid overflow trap.

    <div data-toggle="tooltip" data-container="body" />
  */
  if ($.fn.tooltip) {
    $('[data-toggle="tooltip"]').tooltip();

    /**
     * This block allows user to hover the tooltip without it disappearing
     */
    $('[data-toggle="tooltip-hover"]')
      .tooltip({
        trigger: 'manual',
        html: true,
        animation: false,
      })
      .on('mouseenter', function () {
        $(this).tooltip('show');
        $('.tooltip').on('mouseleave', function () {
          $(this).tooltip('hide');
        });
      })
      .on('mouseleave', function () {
        setTimeout(function () {
          if (!$('.tooltip:hover').length) {
            $(this).tooltip('hide');
          }
        }, 300);
      });
  }

  // using bootstrap tab
  $('a[data-toggle="tab"]').on('shown', function (event) {
    $(event.target).closest('li').addClass('active');
  });

  /**
   * @description enhanced Selectboxes with Select2
   * @param {String} Selector — [data-spy="select"] || [data-select2]
   * @param {Object} Options — use data-attributes, and refer to plugins' docs
   *
   * 1.
   * On modals it's advised to render selectbox on modal instead of body
   * https://select2.org/troubleshooting/common-problems
   * The thing is that >4.0 support data-attr api
   * but the dropdownParent option is still expecting
   * a jqueryObject, and the plugin is not prepared to do conversion
   * as commented on the issue bellow. Solution is to use non-standard
   * select2 data-attribute naming that he can't map to an existent option
   * More info:
   *  https://github.com/select2/select2/issues/4289#issuecomment-208092613
   *
   * Since we have a huge codebase, and i don't know if anyone will ever
   * read this, I've "defaulted" all selectboxes rendered inside a .c-dialog
   * to use it as a container, because otherwise they would be invisible
   */
  if ($.fn.select2) {
    $('[data-spy="select"], [data-select2]').each(function () {
      const $this = $(this);
      const tags = $this.data('tags');
      const placeholderElem = $this.attr('placeholder');
      const placeholderText = placeholderElem || 'Select your option';
      // [1]
      const ddParent = $($this.data('select2-dd-container') || $this.closest('.c-dialog')[0] || document.body);
      const ddClass = $this.data('select2-dd-class') || '';
      $this
        .select2({
          tags,
          minimumResultsForSearch: tags ? 0 : 10,
          dropdownParent: ddParent,
          // HTML setup requisite:
          // include_blank: true, placeholder: "TEXT"
          placeholder: placeholderText,
        })
        .data('select2')
        .$dropdown.addClass(ddClass);

      if ($this.attr('id') === 'switchapps-control') {
        $this.on('change', function () {
          const appId = $(this).val();
          const currentUrl = document.location.href;
          let newUrl;

          if (/apps/.test(currentUrl)) {
            newUrl = currentUrl.replace(/\/apps\/\d+(\/.*$|$)/g, `/apps/${appId}`);
          } else {
            newUrl = `${currentUrl}/apps/${appId}`;
          }

          window.location = newUrl;
        });
      }
    });
  }

  $doc.on('click', '[rel="external"]', function () {
    const width = 640;
    const height = 480;
    const top = window.screen.availHeight / 2 - height / 2;
    const left = window.screen.availWidth / 2 - width / 2;
    window.open(this.href, 'external', `width=${width},height=${height},top=${top},left=${left}`);
    return false;
  });

  // run this to ensure anything that binds to scroll will get touched on load
  $win.scroll();

  const toggleHeaderSearch = function (triggerElm) {
    const $headerLocal = $('[data-js-header]');
    const $headerSearchComponent = triggerElm.parent('[data-header-search]');
    const $headerSearchInput = triggerElm.prev('[data-js-search-form]').find('input[type=search]');

    $headerLocal.toggleClass('search-is-open');
    $headerSearchComponent.toggleClass('is-open');
    if ($headerLocal.hasClass('search-is-open')) {
      setTimeout(function () {
        $headerSearchInput.focus();
      }, 500);
    }
  };

  const handleDropdownSearchAnimation = function (input) {
    const $submitBtn = input.next('button[type=submit]');

    input.on('input', function () {
      if (input.val()) {
        $submitBtn.addClass('visible');
      } else {
        $submitBtn.removeClass('visible');
      }
    });
  };

  $doc.on('click', '[data-toggle="header-search"]', function () {
    toggleHeaderSearch($(this));
  });

  /* drop down search fields are in the nav and show the search icon button once search field has text within it */
  $doc.on('focus', '[data-js-dropdown-search] input[type=search]', function () {
    handleDropdownSearchAnimation($(this));
  });

  /* Detect inline search forms and ensure they update inline withour reloads */
  const inlineSearchForms = $('form[data-js-inline-search]');

  // Prevent unnecessary page submit on search pages which show search results without a page reload, prevent submit and set up URL rewriting on search
  inlineSearchForms.on('submit', function (evt) {
    evt.preventDefault();
  });

  inlineSearchForms.find('input[type=search]').on('keyup', function (evt) {
    const ENTER = 13;
    if (!evt.simulated && evt.which === ENTER) {
      return;
    } /* enter has no special meaning as search already performed by this point */

    const searchQuery = $(this)
      .val()
      .replace(/(^\s+|\s+$)/, '');
    const searchResults = $('#addsearch-results');

    if (searchQuery.length > 0) {
      searchResults.show();
      /* Trigger search automatically on every keypress */
      const e = jQuery.Event('keyup');
      e.keyCode = ENTER; // # Enter
      e.which = ENTER;
      $(this).trigger(e);
    } else {
      // eslint-disable-next-line no-use-before-define
      const url = updateUrlParameter('q', false);
      window.history.pushState(null, null, url);
      searchResults.hide();
    }
  });

  // Prevent default event on click on dropdown/meganav toggles
  $('.c-dropdown__toggle, .c-meganav__toggle').on('click', function (event) {
    event.preventDefault();
  });

  // Expand & Collapse text button
  // We use .prev() method so the follow order is required
  /*
    ex.
    <p data-js="target-toggle-text" class="hide"></p>
    <label class="c-button c-button--view-more" data-js="btn-toggle-text" data-label-default="Expand">Expand</label>
  */
  const targetToggleText = $('[data-js="target-toggle-text"]');

  $(document.body).on('click', '[data-js="btn-toggle-text"]', function () {
    $(this).each(function () {
      // Action function
      $(this).prev(targetToggleText).toggleClass('hide');
      if ($(this).prev(targetToggleText).hasClass('hide')) {
        $(this).text($(this).data('label-default'));
        $(this).removeClass('is-open');
      } else {
        $(this).text('- Close -');
        $(this).addClass('is-open');
      }
    });
  });

  // Tab system to load content (similar to ajax)
  /*
    ex:
        <li ... data-js="tab-item" data-target="tab-content-description"></li>
        <div data-js="tab-content-description"></div>

        <li ... data-js="tab-item" data-target="tab-content-doc"></li>
        <div class="hide" data-js="tab-content-doc"></div>
  */

  // We use "$(document.body).on('click'..)"" because this tab switching is being used within dynamic content
  $(document.body).on('click', '[data-js="tab-item"]', function () {
    const $this = $(this);
    if (!$this.hasClass('is-active')) {
      $this.toggleClass('is-active');
      $('[data-js="tab-item"]').not($this).removeClass('is-active');

      // Select content to show & hide
      const target = $this.data('target');
      const dynamicTarget = $(`[data-js='${target}']`);
      dynamicTarget.show();
      $("[data-js^='tab-content-']").not(dynamicTarget).hide();
    }

    // If data-id existis, so it'll update url Hash
    // Use case: filter in the /resource page
    // We are using the data-id attribue to avoid page scrolling when hash change (ex. id)
    if ($this.data('id') && $this.data('id') !== '') {
      window.location.hash = $this.data('id');
    }
  });
});

$(document).ready(function () {
  // This code allows textareas to grown according to their content
  // Base height: $s-baseline * 3;
  $("[data-js='textarea-autogrow']").autoResize();

  // Close news/temporary banners
  const $bannerClose = $('[data-banner-close]');
  $bannerClose.on('click', function () {
    $(this).closest('[data-banner]').remove();
    $('.show-announcement').removeClass('show-announcement');
    const cookieId = $bannerClose.data('banner-cookie-id');
    const cookie = $.cookie(cookieId) || '';
    const bannerId = $bannerClose.data('banner-id');
    $.cookie(cookieId, `${cookie.replace(`${bannerId},`, '') + bannerId},`, { expires: 90 });
  });

  // Styling select boxes, when they have include_blank via Rails
  // Styles in _c.input.scss
  $('[data-add-state-to-native-select]').each(function () {
    const $this = $(this);
    // When options are selected & their value isn't empty (blank/placholder)
    if ($this.val().length > 0) {
      $this.addClass('is-selected');
    }

    $this.on('change', function () {
      if ($this.val().length <= 0) {
        $this.removeClass('is-selected');
      } else {
        $this.addClass('is-selected');
      }
    });
  });
});

// Retrieves a query parameter from a URL
// - name: the query parameter
// - url: the URL where the query parameter is fetched. If null, window.location.href is used
// eslint-disable-next-line no-unused-vars
window.getQueryParam = function getQueryParam(_name, _url) {
  const url = _url || window.location.href;

  const name = _name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`); // regex to find the parameter
  const results = regex.exec(url); // search parameter

  if (!results) return null; // If parameter doesn't exist
  if (!results[2]) return ''; // If parameter exists but don't have any value

  // Return parameter value
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// Returns a new URL with the value of query parameter updated
// - key: the query parameter
// - value: the new value of the query parameter
// - url: the URL where the query parameter is fetched. If null, window.location.href is used
window.updateUrlParameter = function updateUrlParameter(key, value, _url) {
  let url = _url || window.location.href;

  // Remove the hash part before operating on the url
  const i = url.indexOf('#');
  const hash = i === -1 ? '' : url.substr(i);
  url = i === -1 ? url : url.substr(0, i);

  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';

  if (!value) {
    // Remove key-value pair if value is empty
    url = url.replace(new RegExp(`([&]?)${key}=.*?(&|$)`, 'i'), '');
    if (url.slice(-1) === '?') {
      url = url.slice(0, -1);
    }
  } else if (url.match(re)) {
    // if parameter already exists, update the value
    url = url.replace(re, `$1${key}=${value}$2`);
  } else {
    // if parameter doesn't exist, set the value
    url = `${url + separator + key}=${value}`;
  }
  return url + hash;
};

window.showFormErrors = ($form, response) => {
  $form.find('span.error').remove();

  const sanitizedErrorSpan = (errorMessage) => {
    const span = document.createElement('span');
    span.className = 'error';
    span.textContent = errorMessage;
    return span;
  };

  const { errors } = response;

  Object.entries(errors).forEach(([modelName, modelErrors]) => {
    Object.entries(modelErrors).forEach(([errorField, [errorMessage]]) => {
      const $inputField = $form.find(`.${modelName}_${errorField}`);
      const $dataFieldError = $inputField.parent('.c-form__block').find('[data-field-error]');

      if ($dataFieldError.length > 0) {
        $dataFieldError.append(sanitizedErrorSpan(errorMessage));
      } else {
        $inputField.append(sanitizedErrorSpan(errorMessage));
      }
    });
  });

  if (response.message) {
    Alert.error(response.message);
  }
};
